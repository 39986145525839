<template>
    <div>
        <el-dialog :visible.sync="visible" center :close-on-click-modal="true" @close="handleClose"
      custom-class="dialog_normalsuo dialog_normalpower" :append-to-body="true">
      <div>
        <div class="detail_cont">
          <div class="detail_cont_text">
            Your permission cannot browse this content. Click the "renew" button to upgrade your permission
          </div>

        </div>
      </div>
      <div class="flex_center">
        <el-button class="btn_export btn_exportCCC" @click="handleClose()">OK</el-button>
        <el-button class="btn_export" @click="$router.push({
          path: '/index/addCredit',
        })">Subscribe</el-button>
      </div>
    </el-dialog>
    </div>
</template>

<script>

export default {
  
    data() {
        return {
            visible:false,
            //page: this.currentPage

            token: sessionStorage.getItem('token'),
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() { },
        },

    },
    created() {
        
        this.$bus.$on('show-permission-dialog', (on)=>{
            this.showDialog()
        });
    },
    beforeDestroy() {
        this.$bus.$off('show-permission-dialog', this.showDialog);
    },
    methods: {
        handleClose(value){
            this.visible = false;
        },
        showDialog(value){ 

            this.visible = true;
        },
        //关闭弹窗
   
 
        onSubmitquxiao() {
      this.$router.push({
        path: '/index/addCreditt',
      });
   



        },
    },
};
</script>
<style scoped>
.detail_cont {
    max-height: 85vh;
    overflow: auto;

}



/* .detail_cont_text {
    font-size: 18px;
    color: #1A2332;
    line-height: 30px;
    padding: 20px 0px;
    margin-top: 20px;
} */



</style>
