<template>
    <div>
        <el-dialog 
            :visible.sync="reLoginPop" 
            center 
            :close-on-click-modal="true" 
            @close="closeDialog" 
            custom-class="dialog_normalsuo" 
            :append-to-body="true">
            <div class="detail_cont">
                <div class="dialog_detail_title">Account logged out</div>
                <div class="detail_cont_text" style="padding: 0px;">
                    Your account has been logged in elsewhere, and this login is invalid.
                </div>
                <div class="flex_center_between_box phoen_center_flex_warp" style="justify-content: center !important; margin-top: 20px">
                    <el-button class="btn_cancleA" @click="closeDialog">Close</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        reLoginPop: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        
    },
    methods: {
        closeDialog() {
            // this.reLoginDialog = false; // 关闭对话框
            sessionStorage.clear()
            window.location.href = '/'
            localStorage.removeItem('token')
            localStorage.removeItem('authCode')
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('menuItem')
            bus.$emit('handleShowLogin', true)// 跳转到登录
            localStorage.removeItem("userInfoImg");
        },
    },
};
</script>

<style>
.dialog_detail_title {
  font-family: 'DMSans Bold';
  font-size:1.4285714285714286rem ;
  color: #1a2332;
}

.btn_cancleA {
    width: 10rem;
    height: 2.71rem;
    background: #8497AB !important;
    border-radius: 0.71rem;
    color: #ffffff !important;
}

/* .el-button:focus, .el-button:hover {
    color: #ffffff;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
} */
</style>