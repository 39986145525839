import { render, staticRenderFns } from "./PermissionDialog.vue?vue&type=template&id=7533406a&scoped=true&"
import script from "./PermissionDialog.vue?vue&type=script&lang=js&"
export * from "./PermissionDialog.vue?vue&type=script&lang=js&"
import style0 from "./PermissionDialog.vue?vue&type=style&index=0&id=7533406a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7533406a",
  null
  
)

export default component.exports